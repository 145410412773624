import React from "react";
import styled, { css } from "styled-components";

const seriff = "Times New Roman";
const sansSeriff = "Arial";

const Theme = {
  colors: {
    defaultScreenBackgroundColor: "#FFFFFF",
    primary: "#0053A2",
    primaryLight: "#2D8FE8",
    primaryLightest: "#E0EEFD",
    primaryDark: "#083C6F",
    primaryDarkest: "#1C2936",
    secondary: "#2E8540",
    secondaryLight: "#31B722",
    secondaryLightest: "#E0EDE3",
    secondaryDark: "#175624",
    secondaryDarkest: "#092D10",
    base: "#AFAFAF",
    baseLight: "#DADADA",
    baseLightest: "#F5F5F5",
    baseDark: "#828282",
    baseDarkest: "#3D3D3D",
    basePaper: "#FFFFFF",
    baseInk: "#000000",
    accent: "#D8BE0E",
    accentLight: "#F5EA14",
    accentLightest: "#F9F6DA",
    accentDark: "#BEA70E",
    warning: "#D0021B",
    warningLight: "#F9D9DD",
    warningDark: "#930012",
  },
  lineChartColors: {
    baseDark: "#828282", // last color
    primary: "#0053A2", // first color
    primaryLight: "#2D8FE8",
    secondaryLight: "#31B722",
    secondary: "#2E8540",
  },
  fonts: {
    seriff: `${seriff}`,
    sansSerrif: `${sansSeriff}`,
    h1: {
      fontFamily: `${seriff}`,
      fontWeight: 300,
      fontSize: 40,
      letterSpacing: -1,
    },
    h1Light: {
      fontFamily: `${seriff}`,
      fontWeight: 300,
      fontSize: 40,
      letterSpacing: -1,
      color: "#FFFFFF",
    },
    h2: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 32,
    },
    h3: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 24,
    },
    h4: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 600,
      fontSize: 20,
    },
    h5: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 600,
      fontSize: 18,
    },
    body: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 400,
      fontSize: 16,
    },
    bodyBold: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 16,
    },
    bodyOl: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 400,
      fontSize: 16,
    },
    bodyUl: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 400,
      fontSize: 16,
    },
    bodySmall: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 500,
      fontSize: 13,
    },
    bodySmallBold: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 13,
    },
    bodyMicro: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 500,
      fontSize: 10,
    },
    bodyHyperlink: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 400,
      fontSize: 16,
    },
    bodySmallHyperlink: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 500,
      fontSize: 13,
    },
    bodyInactive: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 400,
      fontSize: 16,
      color: "#FFFFFF",
    },
    bodyWhite: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 400,
      fontSize: 16,
      color: "#FFFFFF",
    },
    bodySmallWhite: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 500,
      fontSize: 13,
      color: "#FFFFFF",
    },
    bodySmallWhiteBold: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 13,
      color: "#FFFFFF",
    },
    bodyAbstract: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 200,
      fontSize: 28,
    },
    btnPaper: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 16,
      color: "#FFFFFF",
    },
    btnInk: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 16,
    },
    btnLargePaper: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 22,
      color: "#FFFFFF",
    },
    btnLargeInk: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 22,
    },
    btnPrimary: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 16,
      color: "#0053A2",
    },
    btnPrimaryDark: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 16,
      color: "#083C6F",
    },
    btnBaseDarkest: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 16,
      color: "#3D3D3D",
    },
    btnBaseLight: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 16,
      color: "#DADADA",
    },
    btnBase: {
      fontFamily: `${sansSeriff}`,
      fontWeight: 700,
      fontSize: 16,
      color: "#AFAFAF",
    },
  },
};

export const ThemeContext = React.createContext();

const ThemeContextProvider = ({ children }) => (
  <ThemeContext.Provider value={Theme}>{children}</ThemeContext.Provider>
);
export default ThemeContextProvider;

// Form labels
export const LabelStyle = styled.label`
  font-family: ${Theme.fonts.bodySmall.fontFamily};
  font-size: ${Theme.fonts.bodySmall.fontSize}px;
  font-weight: ${Theme.fonts.bodySmall.fontWeight};
  @media (min-height: 768px) and (min-width: 1336px) {
    font-size: ${Theme.fonts.body.fontSize}px;
  }
`;

// Input for use in Filter components
export const FilterInput = styled.input`
  margin-top: 5px;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  padding-left: 10px;
  :placeholder-shown {
    color: rgb(128, 128, 128);
  }
  height: 28px;
  font-size: 12px;
  @media (min-height: 768px) and (min-width: 1336px) {
    height: 38px;
    font-size: 14px;
  }
`;

// Loading Symbol
export const Loader = styled.div(
  ({ width = 20, height = 20 }) => css`
  align-self: center;
  animation:spin 1s infinite linear;
  border:solid 2vmin transparent;
  border-radius:50%;
  border-right-color:#E6E6E6;
  border-top-color:#E6E6E6;
  box-sizing:border-box;
  height:${height}vmin;
  left:calc(50% - 10vmin);
  top:calc(50% - 10vmin);
  width:${width}vmin;
  z-index:1;
  &:before {
    animation:spin 2s infinite linear;
    border:solid 2vmin transparent;
    border-radius:50%;
    border-right-color:#808080;
    border-top-color:#808080;
    box-sizing:border-box;
    content:"";
    height:${height - 4}vmin;
    left:0;
    position:absolute;
    top:0;
    width:${width - 4}vmin;
  }
  &:after {
    animation:spin 3s infinite linear;
    border:solid 2vmin transparent;
    border-radius:50%;
    border-right-color:#000;
    border-top-color:#000;
    box-sizing:border-box;
    content:"";
    height:${height - 8}vmin;
    left:2vmin;
    position:absolute;
    top:2vmin;
    width:${width - 8}vmin;
  }
}

@keyframes spin {
  100% {
    transform:rotate(360deg);
  }
}
`
);

import { createSlice } from "@reduxjs/toolkit";

export const contextMenuSlice = createSlice({
  name: "contextMenu",
  initialState: {
    contextMenus: [{ visible: false }, { visible: false }],
  },
  reducers: {
    toggleContextMenu: (state, { payload }) => {
      const index = payload;
      const status = state.contextMenus[index].visible;
      state.contextMenus[index].visible = !status;
    },
  },
});

export const { toggleContextMenu } = contextMenuSlice.actions;
export const contextMenuSelector = (state) => state.contextMenu.contextMenus;

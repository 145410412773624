/* eslint-disable no-debugger */
import { createSlice } from "@reduxjs/toolkit";

export const subnavMenuSlice = createSlice({
  name: "subnavSlice",
  initialState: {
    isOpen: false,
    items: [],
    page: "",
  },
  reducers: {
    renderSubnavMenu: (state, { payload }) => {
      state.isOpen = true;
      state.items = payload.items;
      state.page = payload.page;
    },
  },
});

export const { renderSubnavMenu, clearSubnavMenu } = subnavMenuSlice.actions;
export const subNavMenuSelector = (state) => state.subnavMenu;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { defaultHeaders } from "../../providers/networkProviders";

export const getTable = createAsyncThunk(
    "noncompliance/getTable",
    async (_, { getState, rejectWithValue }) => {
      const { user } = getState();
      const { token } = user;
      try {
        const requestOptions = {
          method: "GET",
          headers: defaultHeaders(token),
          credentials: "include",
        };
        const response = await fetch(`${API}/crds/non-compliance/table`, requestOptions);
        const data = await response.json();
        if (response.status === 200) {
          const copyData = { ...data };
          return copyData;
        }
        return rejectWithValue(data.detail);
      } catch (e) {
        return rejectWithValue(`${e}: An error has occured within the server`);
      }
    }
  );

export const getChartsData = createAsyncThunk(
  "noncompliance/getChartsData",
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState();
    const { token } = user;
    try {
      const requestOptions = {
        method: "GET",
        headers: defaultHeaders(token),
        credentials: "include",
      };
      const response = await fetch(
        `${API}/crds/non-compliance/charts`,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const submitNonCompFilter = createAsyncThunk(
  "noncompliance/submitNonCompFilter",
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState()
    const { token } = user
    try {
      const requestOptions = {
        method: "GET",
        headers: defaultHeaders(token),
        credentials: "include"
      }
      const response = await fetch(
        `${API}/crds/non-compliance/filter`,
        requestOptions
      )
      const data = await response.json()
      if (response.status === 200) {
        const copyData = { ...data }
        return copyData
      }
      return rejectWithValue(data.detail)
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`)
    }
    
  }
)

export const nonComplianceSlice = createSlice({
    name: "nonCompliance",
    initialState: {
      isError: false,
      errorMessage: "",
      table: [],
      charts: [],
      newtable: [],
      isFetching: false,
      eventsAndSrcs: [],
    },
    reducers: {},
    extraReducers: {
      [getTable.fulfilled]: (state, { payload }) => {
        state.table = payload;
        state.isFetching = false;
        return state;
      },
      [getTable.pending]: (state) => {
        state.isFetching = true;
      },
      [getTable.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      },
      [getChartsData.fulfilled]: (state, { payload }) => {
        state.charts = payload;
        state.isFetching = false;
        return state;
      },
      [getChartsData.pending]: (state) => {
        state.isFetching = true;
      },
      [getChartsData.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      },
      [submitNonCompFilter.fulfilled]: (state, { payload }) => {
        state.newtable = payload.newtable;
        // state.newcharts = payload.charts;
        state.isFetching = false;
        state.isError = false;
        return state
      },
      [submitNonCompFilter.pending]: (state) => {
        state.isFetching = true;
      },
      [submitNonCompFilter.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      },
    },
});

export const nonComplianceSelector = (state) => state.crds.noncompliance
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { defaultHeaders } from "../../providers/networkProviders";



export const getTierOne = createAsyncThunk(
  "drilldown/getTierOne",
  async (queryParams, { getState, rejectWithValue }) => {
    // const { user, admin } = getState();
    const { admin } = getState();
    // const { token } = user;
    const { useHANA } = admin;
    try {
      const requestOptions = {
        method: "GET",
        // headers: defaultHeaders(token),
        credentials: "include",
      };
      let queryString = `${API}/journey-cohorts/${queryParams}`;
      if (useHANA) {
        queryString = `${API}/journey-cohorts/HANA/${queryParams}`;
      }
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getTierTwo = createAsyncThunk(
  "drilldown/getTierTwo",
  async (queryParams, { getState, rejectWithValue }) => {
    // const { user, admin } = getState();
    const { admin } = getState();
    const { useHANA } = admin;
    try {
      const requestOptions = {
        method: "GET",
        // headers: defaultHeaders(token),
        credentials: "include",
      };
      let queryString = `${API}/journey-cohorts/${queryParams}`;
      if (useHANA) {
        queryString = `${API}/journey-cohorts/HANA/${queryParams}`;
      }
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getTierThreeSunburst = createAsyncThunk(
  "drilldown/getTierThreeSunburst ",
  async (queryParams, { getState, rejectWithValue }) => {
    // const { user, admin } = getState();
    const { admin } = getState();
    const { useHANA } = admin;
    try {
      const requestOptions = {
        method: "GET",
        // headers: defaultHeaders(token),
        credentials: "include",
      };
      let queryString = `${API}/journey-drilldown-sunburst/${queryParams}`;
      if (useHANA) {
        queryString = `${API}/journey-drilldown-sunburst/HANA/${queryParams}`;
      }
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getTierFour = createAsyncThunk(
  "drilldown/getTierFour",
  async (queryParams, { getState, rejectWithValue }) => {
    // const { user, admin } = getState();
    const { admin } = getState();
    const { useHANA } = admin;
    try {
      const requestOptions = {
        method: "GET",
        // headers: defaultHeaders(token),
        credentials: "include",
      };
      let queryString = `${API}/journey-drilldown-details/${queryParams}`
      if (useHANA) {
        queryString = `${API}/journey-drilldown-details/HANA/${queryParams}`;
      }
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const drilldownSlice = createSlice({
  name: "drilldown",
  initialState: {
    tierOne: [],
    tierTwo: [],
    tierThreeSunburst: {},
    tierFour: {},
    lastUpdate: null,
    isFetching: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    resetAll: (state) => {
      state.tierOne = [];
      state.tierTwo = [];
      state.tierThreeSunburst = {};
      state.tierFour = {};
    },
    resetTierTwo: (state) => {
      state.tierTwo = [];
    },
    resetTierThree: (state) => {
      state.tierThreeSunburst = {};
    },
    resetTierFour: (state) => {
      state.tierFour = {};
    },
  },
  extraReducers: {
    [getTierOne.fulfilled]: (state, { payload }) => {
      state.tierOne = payload;
      state.isFetching = false;
      return state;
    },
    [getTierOne.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [getTierOne.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getTierTwo.fulfilled]: (state, { payload }) => {
      state.tierTwo = Object.values(payload).map((values) => values);
      state.isFetching = false;
      return state;
    },
    [getTierTwo.pending]: (state) => {
      state.isFetching = true;
    },
    [getTierTwo.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getTierThreeSunburst.fulfilled]: (state, { payload }) => {
      state.tierThreeSunburst = payload;
      state.isFetching = false;
      return state;
    },
    [getTierThreeSunburst.pending]: (state) => {
      state.isFetching = true;
    },
    [getTierThreeSunburst.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getTierFour.fulfilled]: (state, { payload }) => {
      state.tierFour = payload;
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = "";
      return state;
    },
    [getTierFour.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [getTierFour.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export const {
  drilldownData,
  resetTierTwo,
  resetTierThree,
  resetTierFour,
  resetAll,
} = drilldownSlice.actions;
export const drilldownSelector = (state) => state.drilldown;

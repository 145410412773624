import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";

export const getEventExplorer = createAsyncThunk(
  "eventExplorer/getEventExplorer",
  async (queryParams, { getState, rejectWithValue }) => {
    const { admin } = getState();
    // const { useHANA } = admin;
    try {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      };
      const queryString = `${API}/event-explorer/${queryParams}`;
      // if (useHANA) {
      //   queryString = `${API}/event-explorer/HANA/${queryParams}`;
      // }
      const response = await fetch(
        queryString, 
        requestOptions
        );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getEventExplorerLookup = createAsyncThunk(
  "eventExplorer/getEventExplorerLookup",
  async (_, { getState, rejectWithValue }) => {
    const { admin } = getState();
    const { useHANA } = admin;
    try {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      };
      const queryString = `${API}/event-explorer-lookup`;
      // if (useHANA) {
      //   queryString = `${API}/event-explorer-lookup/HANA`;
      // }
      const response = await fetch(
        queryString, 
        requestOptions
        );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const eventExplorerSlice = createSlice({
    name: "eventExplorer",
    initialState: {
      eventExplorerLookupData: {},
      eventExplorerData: {},
      isFetching: null,
      isError: false,
      errorMessage: ""
    },
    reducers: {},
    extraReducers: {
      [getEventExplorer.fulfilled]: (state, { payload }) => {
        state.eventExplorerData = payload;
        state.isError = false;
        state.isFetching = false;
        return state;
      },
      [getEventExplorer.pending]: (state) => {
        state.isFetching = true;
      },
      [getEventExplorer.rejected]: (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload;
      },
      [getEventExplorerLookup.fulfilled]: (state, { payload }) => {
        state.eventExplorerLookupData = payload;
        state.isError = false;
        return state;
      },
      [getEventExplorerLookup.rejected]: (state, { payload }) => {
        state.isError = true;
        state.errorMessage = payload;
      },
    },
  });
  
  export const eventExplorerSelector = (state) => state.eventExplorer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { defaultHeaders } from "../../providers/networkProviders";

export const createUserSavedFilter = createAsyncThunk(
  "userFilters/createUserSavedFilter",
  async (filterData, { rejectWithValue }) => {
    try {
      const { submissionData } = filterData;
      const requestOptions = {
        method: "POST",
        headers: defaultHeaders(filterData.token),
        body: JSON.stringify(submissionData),
      };
      const response = await fetch(`${API}/saved-filter`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);
export const getUserSavedFilters = createAsyncThunk(
  "userFilters/getUserSavedFilters",
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState();
    const { token } = user;
    try {
      const requestOptions = {
        method: "GET",
        headers: defaultHeaders(token),
        credentials: "include",
      };
      const response = await fetch(`${API}/saved-filters`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        const copyData = [...data.saved_filters];
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const userFilterSlice = createSlice({
  name: "userFilters",
  initialState: {
    filters: "",
    activeFilter: {},
    isFetching: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [getUserSavedFilters.fulfilled]: (state, { payload }) => {
      state.filters = payload;
      state.isFetching = false;
      return state;
    },
    [getUserSavedFilters.pending]: (state) => {
      state.isFetching = true;
    },
    [getUserSavedFilters.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [createUserSavedFilter.fulfilled]: (state, { payload }) => {
      state.filters = payload.saved_filters;
      state.isError = false;
      state.isFetching = false;
      return state;
    },
    [createUserSavedFilter.pending]: (state) => {
      state.isFetching = true;
    },
    [createUserSavedFilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export const userFilterSelector = (state) => state.userFilter;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { defaultHeaders } from "../../providers/networkProviders";

export const getLastUpdate = createAsyncThunk(
  "journeys/getLastUpdate",
  async (_, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "GET",
      };
      const response = await fetch(`${API}/last-update`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const submitSavedFilter = createAsyncThunk(
  "userFilters/submitSavedFilter",
  async (filterData, { rejectWithValue }) => {
    // eslint-disable-next-line camelcase
    const { token, value, visualizationId } = filterData;

    try {
      const requestOptions = {
        method: "GET",
        headers: defaultHeaders(token),
      };
      const response = await fetch(
        `${API}/saved-filter/${value}`,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { data: { ...data }, visualizationId };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getMinAndMaxDates = createAsyncThunk(
  "journeys/minAndMaxDates",
  async (_, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "GET",
      };
      const response = await fetch(`${API}/date-info`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const journeySlice = createSlice({
  name: "journeyHome",
  initialState: {
    // Currently limited to two max.
    journeyComparisonData: { drop_down: [], metrics: [], population: [] },
    sunbursts: [
      {
        journeyExpData: null,
        journeyFilteredData: null,
      },
      {
        journeyExpData: null,
        journeyFilteredData: null,
      },
    ],
    journeyExpData: null,
    data: null,
    lastUpdate: null,
    minDate: null,
    maxDate: null,
    isFetching: false,
    isError: false,
    errorMessage: "",
    eventsAndSrcs: [],
    reset: false,
  },
  reducers: {},
  extraReducers: {
    [getLastUpdate.fulfilled]: (state, { payload }) => {
      state.lastUpdate = payload;
      state.isError = false;
      state.isFetching = false;
      return state;
    },
    [getLastUpdate.pending]: (state) => {
      state.isFetching = true;
    },
    [getLastUpdate.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getMinAndMaxDates.fulfilled]: (state, { payload }) => {
      state.minDate = payload[0].date_value;
      state.maxDate = payload[1].date_value;
      state.isError = false;
      state.isFetching = false;
      return state;
    },
    [getMinAndMaxDates.pending]: (state) => {
      state.isFetching = true;
    },
    [getMinAndMaxDates.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export const { getJourneys, resetSunburst, resetSingleSunburst, hasReset } =
  journeySlice.actions;
export const journeySelector = (state) => state.journeys;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { defaultHeaders } from "../../providers/networkProviders";

export const getKpiDashboard = createAsyncThunk(
  "kpidash/getKpiDashboard",
  async (_, { getState, rejectWithValue }) => {
    // const { user, admin } = getState();
    const { admin } = getState();
    // const { token } = user;
    const { useHANA } = admin;
    try {
      const requestOptions = {
        method: "GET",
        // headers: defaultHeaders(token),
      };
      let queryString = `${API}/kpi-dash`
      if (useHANA) {
        queryString = `${API}/kpi-dash/HANA`;
      }
      const response = await fetch(queryString, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);


export const kpiDashSlice = createSlice({
  name: "kpiDash",
  initialState: {
    kpiDashData: [],
    data: null,
    lastUpdate: null,
    isFetching: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: {
    [getKpiDashboard.fulfilled]: (state, { payload }) => {
      state.data = true;
      state.kpiDashData = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [getKpiDashboard.pending]: (state) => {
      state.isFetching = true;
    },
    [getKpiDashboard.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export const { getKPIDash } = kpiDashSlice.actions;
export const kpiDashSelector = (state) => state.kpidash;

import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

const PublicRoute = ({
  component: Component,
  isFetching,
  authenticated,
  ...rest
}) => {
  const location = useLocation();
  const onLogin = location.pathname === "/login";
  const isGettingToken = typeof isFetching === "boolean" && !isFetching;
  let getRoute = (props) => <Component {...props} />;
  if (authenticated && isGettingToken) {
    getRoute = () => (
      <Redirect
        to={{
          pathname: "/home",
          state: {
            from: location,
          },
        }}
      />
    );
  }


  return <Route {...rest} render={(props) => getRoute(props)} />;
};

export default PublicRoute;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { defaultHeaders } from "../../providers/networkProviders";

export const getTable = createAsyncThunk(
  "summary/getTable",
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState();
    const { token } = user;
    try {
      const requestOptions = {
        method: "GET",
        headers: defaultHeaders(token),
        credentials: "include",
      };
      const response = await fetch(`${API}/crds/summary/table`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getChartsData = createAsyncThunk(
  "summary/getChartsData",
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState();
    const { token } = user;
    try {
      const requestOptions = {
        method: "GET",
        headers: defaultHeaders(token),
        credentials: "include",
      };
      const response = await fetch(
        `${API}/crds/summary/charts`,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const submitCRDSSummaryFilter = createAsyncThunk(
  "summary/submitCRDSSummaryFilter",
  async (filterData, { rejectWithValue }) => {
    try {
      const { token, filterParams } = filterData;
      const requestOptions = {
        method: "POST",
        headers: defaultHeaders(token),
        body: JSON.stringify(filterParams),
      };
      const response = await fetch(
        `${API}/crds/summary-filter`,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);


export const summarySlice = createSlice({
  name: "summary",
  initialState: {
    isError: false,
    errorMessage: "",
    table: [],
    charts: [],
    isFetching: false,
    eventsAndSrcs: [],
  },
  reducers: {},
  extraReducers: {
    [getTable.fulfilled]: (state, { payload }) => {
      state.table = payload.table;
      state.notice  = payload.notice
      state.population = payload.population;
      state.subPopulation = null;
      state.isFetching = false;
      return state;
    },
    [getTable.pending]: (state) => {
      state.isFetching = true;
    },
    [getTable.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getChartsData.fulfilled]: (state, { payload }) => {
      state.charts = payload;
      state.isFetching = false;
      return state;
    },
    [getChartsData.pending]: (state) => {
      state.isFetching = true;
    },
    [getChartsData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [submitCRDSSummaryFilter.fulfilled]: (state, { payload }) => {
      state.table = payload.table;
      state.subPopulation = payload.subPopulation;
      state.charts = payload.charts;
      state.isFetching = false;
      state.isError = false
      state.errorMessage = ""
      return state;
    },
    [submitCRDSSummaryFilter.pending]: (state) => {
      state.isFetching = true;
    },
    [submitCRDSSummaryFilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export const summarySelector = (state) => state.crds.summary;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { defaultHeaders } from "../../providers/networkProviders";


export const getServerLog = createAsyncThunk(
    "error/getError",
    async (_, { getState, rejectWithValue }) => {
      const { user } = getState();
      const { token } = user;
      try {
        const requestOptions = {
          method: "GET",
          headers: defaultHeaders(token),
        };
        const response = await fetch(`${API}/server-log`, requestOptions);
        const data = await response.json();
        if (response.status === 200) {
          const copyData = { ...data };
          return copyData;
        }
        return rejectWithValue(data.detail);
      } catch (e) {
        return rejectWithValue(`${e}: An error has occured within the server`);
      }
    }
  );



export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        useHANA: false,
        serverLog: "",
    },
    reducers: {
        toggleDB: (state) => {
            state.useHANA = !state.useHANA;
        },
    },
    extraReducers: {
        [getServerLog.fulfilled]: (state, { payload }) => {
            state.serverLog = payload;
            state.isFetching = false;
            return state;
          },
          [getServerLog.pending]: (state) => {
            state.isFetching = true;
          },
          [getServerLog.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = payload;
          }
    }
})

export const { toggleDB } = adminSlice.actions;
export const adminSelector = (state) => state.admin;
import React, { useState } from "react";
import styled from "styled-components";
// import {
//     // Link,
//     Form,
//     Fieldset,
//     Label,
//     TextInput,
//     Button
// } from "@trussworks/react-uswds";
// import { ReactComponent as Logo } from "../assets/icons/icon_logo.svg";

const LoginForm = ({ onSubmit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <main id="main-content">
        {/* <FormContainer data-cy="login-form-container"> */}
          {/* <LogoStyle>
            <Logo fill="#0053A2" />
          </LogoStyle> */}
            {/* <FormTitle>Log In</FormTitle> */}
            <form onSubmit={(e) => onSubmit(e, email, password)} className="display-flex flex-column flex-align-stretch width-full margin-top-neg-2">
                    <label htlmfor="email" className="font-sans-2xs text-base-light margin-bottom-05 display-block text-thin">Email</label>
                    <input
                        type="text"
                        id="email"
                        placeholder="UserID"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="padding-y-1 padding-x-105 shadow-2 hover:bg-primary-lighter radius-pill border-base-lighter font-sans-2xs"
                        required
                    />
                    <label htlmfor="password" className="font-sans-2xs text-base-light margin-bottom-05 display-block text-thin">Password</label>
                    <input
                        type="password"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className="padding-y-1 padding-x-105 shadow-2 hover:bg-primary-lighter radius-pill border-base-lighter font-sans-2xs margin-bottom-1"
                        required
                    />
                    <button type="submit" className="margin-top-2 margin-bottom-1 width-full shadow-2">Submit</button>
            </form>
            {/* <RequestAccountText>
              Dont have an account? <br /> To request an account, email Shannon Voigt
              at: <br />
              <Link href="mailto:Shannon.L.Voigt@irs.gov">
                Shannon.L.Voigt@irs.gov
              </Link>
            </RequestAccountText> */}
        {/* </FormContainer> */}
    </main>
  );
};

const LogoStyle = styled.div`
  text-align: center;
  margin: auto;
        @media(max-width: 600px) {
          width: 250px;
        }
      }
    }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 400px;
  margin: 80px auto 0;
  box-shadow: 4px 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 #808080;
  border-radius: 20px;
  padding: 50px;

  @media (max-width: 600px) {
    width: 100%;
    margin: 15px auto;
    padding: 30px;
  }
`;

const RequestAccountText = styled.span`
  margin-top: 20px;
  text-align: center;
  font-weight: 300;
  font-size: 0.9em;
`;

const FormTitle = styled.h1`
  font-size: 1.5rem;
  color: #5f6988;
  font-weight: 300;
  border-bottom: 1px solid #dee2ef;
  padding-bottom: 3px;
`;

export default LoginForm;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
import { defaultHeaders } from "../../providers/networkProviders";

export const getExplorer = createAsyncThunk(
  "explorer/getExplorer",
  async (_, { getState, rejectWithValue }) => {
    const { user } = getState();
    const { token } = user;
    try {
      const requestOptions = {
        method: "GET",
        headers: defaultHeaders(token),
        credentials: "include",
      };
      const response = await fetch(`${API}/crds/explorer`, requestOptions);
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };

        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const submitDataAnalysisFilter = createAsyncThunk(
  "explorer/submitDataAnalysisFilter",
  async (filterData, { rejectWithValue }) => {
    try {
      const { token, explorerParams } = filterData;
      const requestOptions = {
        method: "POST",
        headers: defaultHeaders(token),
        body: JSON.stringify(explorerParams),
      };
      const response = await fetch(
        `${API}/crds/explorer-filter`,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const submitCRDSExplorerFilter = createAsyncThunk(
  "explorer/submitCRDSExplorerFilter",
  async (filterData, { rejectWithValue }) => {
    try {
      const { token, filterParams } = filterData;
      const requestOptions = {
        method: "POST",
        headers: defaultHeaders(token),
        body: JSON.stringify(filterParams),
      };
      const response = await fetch(
        `${API}/crds/explorer-filter`,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);


export const explorerSlice = createSlice({
  name: "explorer",
  initialState: {
    isError: false,
    errorMessage: "",
    sunburst: [],
    chart: [],
    isFetching: true,
    xtins: {},
  },
  reducers: {
    storeTins: (state, { payload }) => {
      state.xtins = payload
    },
    sampleSize: (state, { payload }) => {
      state.sample = payload
    },
    activatePage: (state, { payload }) => {
      state.active = payload
    }
  },
  extraReducers: {
    [getExplorer.fulfilled]: (state, { payload }) => {
      // state.sunburst = payload.sunburst;
      // state.subPopulation = null;
      state.totalPopulation = payload.sunburst.populationAvg;
      // state.completeJourneyTable = payload.completeJourneyTable;
      // state.chart = payload.chart;
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = "";
      return state;
    },
    [getExplorer.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [getExplorer.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [submitDataAnalysisFilter.fulfilled]: (state, { payload }) => {
      state.sunburst = payload.sunburst;
      state.subPopulation = payload.sunburst.populationAvg;
      state.completeJourneyTable = payload.completeJourneyTable;
      state.chart = payload.chart;
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = "";
      return state;
    },
    [submitDataAnalysisFilter.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [submitDataAnalysisFilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },

    [submitCRDSExplorerFilter.fulfilled]: (state, { payload }) => {
      state.sunburst = payload.sunburst;
      state.subPopulation = payload.sunburst.populationAvg;
      state.completeJourneyTable = payload.completeJourneyTable;
      state.chart = payload.chart;
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = "";
      return state;
    },
    [submitCRDSExplorerFilter.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [submitCRDSExplorerFilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
  },
});

export const explorerSelector = (state) => state.crds.explorer;
export const { storeTins } = explorerSlice.actions
export const { sampleSize } = explorerSlice.actions
export const { activatePage } = explorerSlice.actions

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { userSlice } from "./users/auth.slice";
import { journeySlice } from "./journeys/journey.slice";
import { userFilterSlice } from "./users/userFilter.slice";
import { kpiDashSlice } from "./kpidash/kpidash.slice";
import { contextMenuSlice } from "./contextMenu/contextMenu.slice";
import { subnavMenuSlice } from "./subnavMenu/sunbnav.slice";
import { drilldownSlice } from "./drilldown/drilldown.slice";
import { adminSlice } from "./admin/admin.slice";
import { mineSlice } from "./mine/mine.slice";
import { summarySlice } from "./crds/summary.slice";
import { explorerSlice } from "./crds/explorer.slice";
import { nonComplianceSlice } from "./crds/nonCompliance.slice";
import { eventExplorerSlice } from "./eventExplorer/eventExplorer.slice";

const crdsReducer = combineReducers({
  summary: summarySlice.reducer,
  explorer: explorerSlice.reducer,
  noncompliance: nonComplianceSlice.reducer,
});

const reducer = combineReducers({
  journeys: journeySlice.reducer,
  user: userSlice.reducer,
  userFilter: userFilterSlice.reducer,
  kpidash: kpiDashSlice.reducer,
  mine: mineSlice.reducer,
  contextMenu: contextMenuSlice.reducer,
  subnavMenu: subnavMenuSlice.reducer,
  drilldown: drilldownSlice.reducer,
  admin: adminSlice.reducer,
  eventExplorer: eventExplorerSlice.reducer,
  crds: crdsReducer,
});

const store = configureStore({
  reducer,
  middleware: [...getDefaultMiddleware({ serializableCheck: false })],
});

export default store;

import React from 'react';
import './Home.css';
import Photo from './photo-1518495973542-4542c06a5843.avif';

const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <div className="logo">The Climate Exchange.</div>
          <div className="account-links">
            <a href="/login">Login</a>
          </div>
        </nav>
      </header>
      <main>
        <section className="hero">
          <h1>Live Sustainably, Affordably!</h1>
        </section>
        <section className="subscribe">
          <h2>Subscribe to our email list</h2>
          <form>
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </section>
      </main>
    </div>
  );
};

export default Home;

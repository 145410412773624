import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../config";
// import { defaultHeaders } from "../../providers/networkProviders";

export const getNarrativeList = createAsyncThunk(
  "miner/getNarrativeList",
  async (queryParams, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json" 
        }
      };
      const queryString = `${API}/text-mining/journey-list`;
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getNarrativeSum = createAsyncThunk(
  "miner/getNarrativeSum",
  async (queryParams, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json" 
        }
      };
      const queryString = `${API}/text-mining/journey-corpus${queryParams ? `?${queryParams}` : ''}`;
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getNarrativeLine = createAsyncThunk(
  "miner/getNarrativeLine",
  async (queryParams, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json" 
        }
      };
      const queryString = `${API}/text-mining/journey-line`;
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getNarrativeToken = createAsyncThunk(
  "miner/getNarrativeToken",
  async (queryParams, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "GET",  // Change the method to GET
        headers: {
          "Content-Type": "application/json" 
        }
      };
      const queryString = `${API}/text-mining/journey-token${queryParams ? `?${queryParams}` : ''}`;
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const getNarrativeModel = createAsyncThunk(
  "miner/getNarrativeModel",
  async (queryParams, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "GET",  // Change the method to GET
        headers: {
          "Content-Type": "application/json" 
        }
      };
      const queryString = `${API}/text-mining/journey-model${queryParams ? `?${queryParams}` : ''}`;
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const postCSVSumsData = createAsyncThunk(
  "miner/postCSVSumsData",
  async (requestData, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "POST",  // Change the method to POST
        headers: {
          "Content-Type": "application/json" 
        },
        body: JSON.stringify(requestData), // Include the payload data in the body
      };
      const queryString = `${API}/text-mining/csv-corpus`;
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occured within the server`);
    }
  }
);

export const postTokenData = createAsyncThunk(
  "miner/postTokenData",
  async (requestData, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "POST",  // Change the method to POST
        headers: {
          "Content-Type": "application/json" 
        },
        body: JSON.stringify(requestData), // Include the payload data in the body
      };
      const queryString = `${API}/text-mining/csv-token`;
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occurred within the server`);
    }
  }
);

export const postModelData = createAsyncThunk(
  "miner/postModelData",
  async (requestData, { rejectWithValue }) => {
    try {
      const requestOptions = {
        method: "POST",  // Change the method to POST
        headers: {
          "Content-Type": "application/json" 
        },
        body: JSON.stringify(requestData), // Include the payload data in the body
      };
      const queryString = `${API}/text-mining/csv-model`;
      const response = await fetch(
        queryString,
        requestOptions
      );
      const data = await response.json();
      if (response.status === 200) {
        const copyData = { ...data };
        return copyData;
      }
      return rejectWithValue(data.detail);
    } catch (e) {
      return rejectWithValue(`${e}: An error has occurred within the server`);
    }
  }
);

export const mineSlice = createSlice({
  name: "miner",
  initialState: {
    narrModel: [],
    narrToken: [],
    narrList: [],
    narrSum: [],
    narrLine: [],
    csvSumData: {},
    tokenData: {},
    modelData: {},
    removeList: [], // Defaults for remove and replace lists
    replaceList: [],
    lastUpdate: null,
    isFetching: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    removeListAdd: (state, { payload }) => {
      state.removeList.push(payload);
    },
    removeListDrop: (state, { payload }) => {
      state.removeList.splice(state.removeList.indexOf(payload), 1);
    },
    replaceListAdd: (state, { payload }) => {
      state.replaceList.push(payload);
    },
    replaceListDrop: (state, { payload }) => {
      for (let i=0; i < state.replaceList.length; i+=1) {
        if (state.replaceList[i][0] === payload[0] && state.replaceList[i][1] === payload[1]) {
          state.replaceList.splice(i, 1);
          break;
        }
      }
    },
    clearLists: (state) => {
      state.removeList = [];
      state.replaceList = [];
    }
  },
  extraReducers: {   
    [getNarrativeModel.fulfilled]: (state, { payload }) => {
      state.narrModel = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [getNarrativeModel.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [getNarrativeModel.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getNarrativeToken.fulfilled]: (state, { payload }) => {
      state.narrToken = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [getNarrativeToken.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [getNarrativeToken.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getNarrativeList.fulfilled]: (state, { payload }) => {
      state.narrList = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [getNarrativeList.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [getNarrativeList.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [getNarrativeSum.fulfilled]: (state, { payload }) => {
      state.narrSum = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [getNarrativeSum.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [getNarrativeSum.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }, 
    [getNarrativeLine.fulfilled]: (state, { payload }) => {
      state.narrLine = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [getNarrativeLine.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [getNarrativeLine.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },  
    [postCSVSumsData.fulfilled]: (state, { payload }) => {
      state.csvSumData = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [postCSVSumsData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [postCSVSumsData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }, 
    [postTokenData.fulfilled]: (state, { payload }) => {
      state.tokenData = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [postTokenData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [postTokenData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [postModelData.fulfilled]: (state, { payload }) => {
      state.modelData = payload;
      state.isFetching = false;
      state.isError = false;
      return state;
    },
    [postModelData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    [postModelData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    }
  },
});

export const { getMine, removeListAdd, removeListDrop, replaceListAdd, replaceListDrop, clearLists } = mineSlice.actions;
export const narrSelector = (state) => state.mine
export const csvSumsSelector = (state) => state.mine.csvSumData;
export const tokenSelector = (state) => state.mine.tokenData;
export const modelSelector = (state) => state.mine.modelData;


import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Chart as ChartJS, registerables } from "chart.js";
import { refreshToken, logoutUser } from "./store/users/auth.slice";
import Login from "./views/AccountPage/Login";
// import account from "./views/AccountPage/Login";
// import account from "./views/AccountPage/Login";
import SignUp from "./views/SignUpPage/SignUp";
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";
import Home from "./views/HomePage/Home";
import Admin from "./views/AdminPage/Admin";

ChartJS.register(...registerables);

const App = () => {
  const { isAuthenticated, isFetching, currentUser, location } = useSelector(
    ({ user: { isAuthenticated, isFetching, currentUser, location } }) => ({
      isAuthenticated,
      isFetching,
      currentUser,
      location
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname !== "/login" || location.pathname !== "/signup") {
      dispatch(refreshToken());

      const setTimer = setInterval(() => {
        dispatch(refreshToken());
      }, 60000);

      const stopTimer = () => {
        clearInterval(setTimer);
        dispatch(logoutUser());
      };
      setTimeout(stopTimer, 1000 * 60 * 35);
    }
  }, [dispatch, location.pathname]);

  return (
    <Router>
      <section>
        <Switch>
          {/* <PublicRoute
            exact
            path="/login"
            component={Login}
            key="Login"
            authenticated={isAuthenticated}
            isFetching={isFetching}
          />
          <PublicRoute
            exact
            path="/signup"
            component={SignUp}
            key="Signup"
            authenticated={isAuthenticated}
            isFetching={isFetching}
          /> */}
          <PublicRoute
            exact
            path="/home"
            component={Home}
            authenticated={isAuthenticated}
            isFetching={isFetching}
          />
          {/* <PublicRoute
            exact
            path="/admin"
            component={Admin}
            authenticated={isAuthenticated}
            isFetching={isFetching}
          /> */}
          <Route path="*" component={() => <Redirect to="/home" />} />
        </Switch>
      </section>
    </Router>
  );
};

export default App;
